:root {
  --rlcb-primary: #5BBDB9;
  /* --rlcb-primary: #00A5D9; */
  /* --rlcb-primary: #72B015; */
  --rlcb-primary-light: #DEF2F1;
  /* --rlcb-primary-light: #E2F0CF; */
  /*--rlcb-primary-light: #cbf0fc;*/
  --rlcb-light-grey: #EDEDED;
  --rlcb-lightest-grey: #f7f7f7;
  --rlcb-med-grey: #707070;
  --rlcb-dark-grey: #333333;
  --rlcb-contrast: #ffffff;
}

body {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 16px;
  font-family: 'Work Sans', Arial, Helvetica, sans-serif;
  padding: 10px 10px 13px 10px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

#lumina-chatbot {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  position: relative;
  height: 100%;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  @layer defaults {
      button {
          font-size: inherit;
          display: block;
          border: none;
          cursor: pointer;
      }
  }

  lumina-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 107px;
  }

  lumina-bot {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 0;
    height: 0;
    border-radius: 1.25em;
    box-shadow: 0 3px 10px #00000033;
    background: white;
    padding: .9em;
    height: 100%;
    width: 100%;

    &>*:not(message-list) {
      flex-grow: 0;
    }
  }
}