div.content.citation {
  margin-top: .5rem;
  background-color: var(--rlcb-lightest-grey);

  >* {
    margin: 0 0 .25rem;
    line-height: 120%;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
  }

  p {
    word-wrap: normal;
  }

  a {
    color: var(--rlcb-primary);

    &:hover {
      text-decoration: none;
    }
  }
}