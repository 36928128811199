nav.citations {
  font-size: .85rem;
  margin: .25rem 0;

  button {
    background: transparent;
    color: var(--rlcb-primary);
    cursor: pointer;
    display: inline-block;
    margin-right: .75rem;
    padding: 0;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}